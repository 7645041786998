<template>
  <d2-container>
    <!-- 卡片信息 -->
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>采购订单管理列表</span>
        <el-button style="float: right; padding: 5px" type="primary" @click="onBackPage">返回上级</el-button>
      </div>
      <div class="card-content">
        <el-row :gutter="60" class="mtb10">
          <el-col :span="12">
            <span>采购编号：{{detail.orderNo}}</span>
            <span></span>
          </el-col>
          <el-col :span="12">
            <span>手机号码：{{detail.tel}}</span>
            <span></span>
          </el-col>
        </el-row>
        <el-row :gutter="60"  class="mtb10">
          <el-col :span="12">
            <span>汇款日期：{{detail.date}}</span>
            <span></span>
          </el-col>
          <el-col :span="12">
            <span>商业公司：{{detail.company}}</span>
            <span></span>
          </el-col>
        </el-row>
        <el-row :gutter="60"  class="mtb10">
          <el-col :span="12">
            <div style="display: flex;">
              <div>所属大区：</div>
              <!-- <div>{{detail.areaName}}</div> -->
              <div>
                <template v-for="(item, index) in detail.areaName">
                  <div :key="index">{{item}}</div>
                </template>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <span>汇款对象：{{detail.target}}</span>
            <span></span>
          </el-col>
        </el-row>
        <el-row :gutter="60"  class="mtb10">
          <el-col :span="12">
            <span style="">省总：{{detail.province}}</span>
            <span></span>
          </el-col>
          <el-col :span="12">
            <span>总价：￥{{detail.totalMoney}}</span>
            <span></span>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <!--表格条件配置组件-->
    <div>
      <free-table
        border
        :data="detail.goodsList"
        :column="slotColumn"
        :columnsProps="columnsProps"
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
        <template v-slot:goodsQty="{ row }">
          <el-input
            v-model.number="row.goodsQty"
            type="number"
            placeholder="请输入内容"
            @input="onChangeClick({ ...row })"
          ></el-input>
        </template>

        <template v-slot:goodsImageUrl="{ row }">
          <div @click="showImg(row)" style="margin-top: 7px; cursor: pointer">
            <img :src="row.goodsImageUrl" width="60" height="40" class="image" />
          </div>
        </template>
      </free-table>
    </div>

    <!-- 放大图片 -->
    <el-dialog
      width="20%"
      class="el-dialog-col-2"
      :visible.sync="dialogVisible"
      :close-on-click-modal="true"
    >
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </d2-container>
</template>

<script>
// 引入查询条件配置组件
import FreeTable from "@/views/components/FreeTable";

// 引入接口
import Order from "@/model/modules/mod_order";

export default {
  name: "orderDetail",
  components: {
    FreeTable
  },
  data() {
    return {
      id: {}, //订单id
      detail: {}, //订单详情

      dialogImageUrl: '', //放大图片路径
      dialogVisible: false,

      formSetData: {
        pageIndexB1: 1,
        pageSize: 10
      },

      slotColumn: [
        { label: "药品名称", prop: "goodsName" },
        { label: "药品单价", prop: "goodsUnitPrice" },
        { label: "药品数量", prop: "goodsQty", slotScope: true  },
        { label: "药品总价", prop: "goodsTotalPrice" },
        { label: "药品配图", prop: "goodsImageUrl", slotScope: true },
        { label: '药品规格', prop: 'goodsSpecs' },
        { label: '单位', prop: 'goodsUnit' },
      ],
      columnsProps: {
        width: "auto",
        showOverflowTooltip: true
      },

      // 页码
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100],
        total: 1
      }
    };
  },

  mounted() {
    this.id = this.$route.params.id;
    this.getOrderDetail()
  },
  computed: {

  },

  methods: {
    // 监听药品数量输入框改变
    async onChangeClick(row) {
      console.log(row.goodsQty)
      if(!(/(^[0-9]\d*$)/.test(row.goodsQty))){
        this.$message({
          message: "请输入0或正整数",
          type: "error",
          duration: 1500,
        });
        return
      }else {
        let params = {
          goodsId: row.id,
          orderId: this.id,
          // sum: JSON.stringify(row.goodsQty),
          sum: row.goodsQty,
        }
        const res = await Order.modifyGoodsNum(params)
        if(res.data.code == 0) {
          this.getOrderDetail()
        }else {
          this.$message({
            type: 'error',
            message: res.data.msg,
            duration: 1500
          })
        }
      }
    },

    // 返回上一级
    onBackPage () {
      this.$router.go(-1)
    },

    //  图片放大
    showImg(row) {
      this.dialogVisible = true;
      this.dialogImageUrl = row.goodsImageUrl
    },

    // 获取订单详情
    async getOrderDetail () {
      console.log('detail')
      const res = await Order.pOrderDetail({id: this.id});
      if(res.data.code == 0) {
        this.detail = res.data.body
        console.log(this.detail)
        var arr = this.detail.areaName.split(',')
        this.detail.areaName = arr
      }else {
        this.$message({
          type: 'error',
          message: res.data.msg,
          duration: 1500
        })
      }

    },

    // 列表数据
    async getCourseVideoList() {
      this.formSetData.pageIndexB1 = this.pagination.pageNum;
      this.formSetData.pageSize = this.pagination.pageSize;
      this.formSetData.id = this.queryForm.id;
      const res = await Order.pOrderDetail(this.formSetData);
      this.data = res.data.body.content;
      this.pagination.total = res.data.body.total * 1;
    },

    // 返回上一级
    onBackPage() {
      this.$router.go(-1);
    },

    // 分页选择器切换事件
    getList(e) {
      this.pagination.pageNum = e.page;
      this.pagination.pageSize = e.limit;
      this.getCourseVideoList();
    },

    handleQueryFocus(e) {},

    handleFocus(e) {}
  }
};
</script>

<style scoped>
.mtb10{
  margin: 10px 0;
}

.box-card {
  width: 100%;
  margin: 0 auto 20px auto;
}
</style>
